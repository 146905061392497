import React, { Suspense, lazy } from 'react';
import "@fontsource/jetbrains-mono"; // Defaults to weight 400
import "@fontsource/jetbrains-mono/400.css"; // Specify weight
import "@fontsource/jetbrains-mono/400-italic.css"; // Specify weight and style
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from './layout';
import NotFoundPage from '../404/LostAstronaut404';

// Utilisation de React.lazy pour le chargement dynamique des composants
const HomePage = lazy(() => import('./pages/home/Home'));
const About = lazy(() => import('./pages/about/About'));

const routes = [
    {
        name: "home",
        path: "/",
        component: <HomePage />
    },
    {
        name: "about-me",
        path: "/about-me/",
        component: <About />
    },
    {
        name: "projects",
        path: "/projects",
        component: (
            <div>
                <h1>Projects</h1>
            </div>
        )
    },
    {
        name: "services",
        path: "/services",
        component: (
            <div>
                <h1>Services</h1>
            </div>
        )
    },
    {
        name: "contact",
        path: "/contact",
        component: (
            <div>
                <h1>Contact</h1>
            </div>
        )
    },
];

const App = () => {
    return (
        <Router>
            <Helmet>
                <title>Axel Colmant</title>
                <meta name="description" content="My personal website" />
            </Helmet>
            <Layout routes={routes}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} element={route.component} />
                        ))}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Suspense>
            </Layout>
        </Router>
    );
};

export default App;