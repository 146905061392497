import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CiMenuFries } from 'react-icons/ci';
import { Link } from 'react-router-dom';

const MobileNav = ({ routes }) => {
    const [isOpen, setIsOpen] = useState(false);
    var pathname = useLocation().pathname;
    if (pathname[pathname.length - 1] === "/" && pathname !== "/") {
        pathname = pathname.slice(0, -1);
    }

    const toggleSheet = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="flex justify-center items-center" onClick={toggleSheet}>
                <CiMenuFries className="text-[32px] text-accent" />
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-black/80 z-50 flex flex-col">
                    {/* close button */}
                    <div className='flex justify-end p-4'>
                        <button onClick={toggleSheet} className="text-[32px] text-accent">
                            &times;
                        </button>
                    </div>
                                        
                    {/* logo */}
                    <div className='mt-[10vh] mb-[10vh] text-center text-2xl'>
                        <Link to="/" onClick={toggleSheet}>
                            <h1 className='text-4xl font-semibold'>
                                Axel<span className="text-accent">.</span>
                            </h1>
                        </Link>
                    </div>

                    {/* navigation */}
                    <nav className='flex flex-col justify-center items-center gap-8'>
                        {routes.map((link, index) => (
                            <Link
                                to={link.path}
                                key={index}
                                className={`${link.path === pathname && 'text-accent border-b-2 border-accent'} text-[2.5vh] capitalize hover:text-accent`}
                                onClick={() => {
                                    toggleSheet();
                                }}
                            >
                                {link.name}
                            </Link>
                        ))}
                    </nav>
                </div>
            )}
        </>
    );
};

export default MobileNav;