import React from "react";
import AdminLayout from "./layout";

function AdminApp() {
  return (
    <div>
      <AdminLayout>
        
        
      </AdminLayout>
    </div>
  );
}

export default AdminApp;