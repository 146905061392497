import React from 'react';

const Footer = ({ ...props }) => {
    const parameter = {
        className: `${props.className} bg-transparent overflow-hidden flex flex-row justify-between`,
    }
    const hostname = window.location.hostname;
    const port = window.location.port;
    const http = hostname.includes("localhost") ? "http" : "https";
    const adminUrl = `${http}://admin.${hostname}${port ? `:${port}` : ""}`;
    return (
        <footer {...parameter} id="footer">
            <div className="container mx-auto flex justify-between items-center">
                <p className="text-white/30 text-sm xl:text-lg">© 2024 Axel Colmant</p>

                <div className="flex gap-4">
                    <a href={adminUrl} target="_blank" rel="noreferrer" className="text-white/30 text-sm xl:text-lg">
                        Admin Panel
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;