import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Curve from '../transition/curve/Curve';

const Layout = ({ children, routes }) => {
    return (
        <div className="flex flex-col overflow-hidden gap-0">
            <Curve routes={routes}>
                <Header routes={routes} className="h-[6vh] lg:h-[8vh]" />
                <div className="h-[90vh] lg:h-[86vh]">
                    {children}
                </div>
                <Footer className="h-[4vh] lg:h-[6vh]" />
            </Curve>
        </div>
    );
};

export default Layout;