import React from 'react';
import MainApp from './main/App';
import AdminApp from './admin/App.js';


function App() {
    const hostname = window.location.hostname;
    const url = process.env.REACT_APP_URL;
    if (hostname === `admin.${url}`) {
        return <AdminApp />;
    } else if (hostname === url) {
        return <MainApp />;
    } else {
        return null; // or some default component
    }
}

export default App;
