import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import './style.css';

const anim = (variants) => {
    return {
        variants,
        initial: "initial",
        animate: "enter",
        exit: "exit"
    }
}

export default function Curve({ children, routes }) {
    const pathname = useLocation().pathname;

    const dict_routes = routes.reduce((acc, route) => {
        acc[route.path] = route.name.charAt(0).toUpperCase() + route.name.slice(1);
        return acc;
    }, {});

    // when the user is going to navigate to a path, call an event function
    const targetPath = pathname.length > 1 && pathname[pathname.length - 1] === "/" ? pathname.slice(0, pathname.length - 1) : pathname
    const targetName = dict_routes[targetPath];

    const [dimensions, setDimensions] = useState({
        width: null,
        height: null
    })

    useEffect(() => {
        function resize() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        resize();
        window.addEventListener("resize", resize)
        return () => {
            window.removeEventListener("resize", resize);
        }
    }, [])

    const text2 = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: { duration: .3, ease: [0.76, 0, 0.24, 1] },
        },
        exit: {
            opacity: 1,
        }
    }

    const text1 = {
        initial: {
            opacity: 1,
        },
        enter: {
            opacity: 0,
            top: -100,
            transition: { duration: .75, delay: .45, ease: [0.76, 0, 0.24, 1] },
            transitionEnd: { top: "47.5%" }
        },
        exit: {
            opacity: 0,
        }
    }
    
    return (
        <AnimatePresence mode="wait">
            <div className='page curve' key={pathname}>
                <motion.div {...anim(text1)} className="route w-full">
                    <motion.p className='route' {...anim(text2)} style={{ pointerEvents: 'none' }}>
                        {targetName}
                    </motion.p>
                </motion.div>
                <div style={{ opacity: dimensions.width > 0 ? 0 : 1, pointerEvents: 'none' }} className='background' />
                {dimensions.width > 0 && <SVG {...dimensions} className="svg" />}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0, delay: 0.75 } }}
                    exit={{ opacity: 0, transition: { duration: 0 } }}>
                    {children}
                </motion.div>
            </div>
        </AnimatePresence>
    )
}

const SVG = ({ height, width }) => {

    const initialPath = `
        M0 300 
        Q${width / 2} 0 ${width} 300
        L${width} ${height + 300}
        Q${width / 2} ${height + 600} 0 ${height + 300}
        L0 0
    `

    const targetPath = `
        M0 300
        Q${width / 2} 0 ${width} 300
        L${width} ${height}
        Q${width / 2} ${height} 0 ${height}
        L0 0
    `

    const curve = {
        initial: {
            d: initialPath
        },
        enter: {
            d: targetPath,
            transition: { duration: .75, delay: .45, ease: [0.76, 0, 0.24, 1] },
        },
        exit: {
            d: initialPath,
            transition: { duration: .75, ease: [0.76, 0, 0.24, 1] }
        }

    }

    const slide = {
        initial: {
            top: "-300px"
        },
        enter: {
            top: "-100vh",
            transition: { duration: .75, delay: .45, ease: [0.76, 0, 0.24, 1] },
            transitionEnd: { top: "100vh" }
        },
        exit: {
            top: "-300px",
            transition: { duration: .75, ease: [0.76, 0, 0.24, 1] },
        }

    }

    return (
        <motion.svg {...anim(slide)} className="svg">
            <motion.path {...anim(curve)} />
        </motion.svg>
    )
}