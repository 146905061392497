import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Nav = ({routes}) => {
    var pathname = useLocation().pathname;  

    return (
        <nav className="flex gap-8">
            {routes.map((link, index) => {
                const normalizePath = (path) => path.replace(/\/+$/, '') || '/';
                const underline = normalizePath(link.path) === normalizePath(pathname);

                return (
                    <Link to={link.path} key={index} className={`${underline && "text-accent border-b-2 border-accent"} capitalize font-medium hover:text-accent`}>
                        {link.name}
                    </Link>
                )
            })}
        </nav>
);
}

export default Nav;