// import Link from react

import { Link } from 'react-router-dom';
import Nav from './Nav';
import MobileNav from './MobileNav.jsx';

const Header = ({routes, ...props}) => {
    return (
        <header className="text-white bg-transparent" id="header" {...props}>
            <div className="container m-auto flex justify-between items-center"> 
                <Link to="/" className="flex items-center gap-4 items-center">
                    { /* Logo */ }
                    <img src='/assets/logo-dark.svg' alt='Logo' className='hidden sm:flex cursor-pointer w-[5vh] lg:w-[8vh]' fill="true" />
                    <h1 className="text-2xl lg:text-4xl font-semibold">
                        Axel Colmant<span className="text-accent">.</span>
                    </h1>
                </Link> 

                { /* Desktop Navigation */}
                <div className="hidden xl:flex items-center gap-8">
                    <Nav routes={routes}/>
                </div>

                { /* Mobil Nav */}
                { /* Mobile Nav */ }
                <div className='flex xl:hidden'>
                    <MobileNav routes={routes} />
                </div>
            </div>
        </header>
    );
}

export default Header;
