import React, { useEffect, useRef, useState } from "react";

const NotFoundPage = () => {
    const astronautRef = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const randomX = window.innerWidth;
        const randomY = Math.floor(Math.random() * window.innerHeight);
        setPosition({ x: randomX, y: randomY });
    }, []);
    const [velocity, setVelocity] = useState({ dx: 2, dy: 2 });
    const [rotation, setRotation] = useState(0);

    useEffect(() => {

        const interval = setInterval(() => {
            setPosition((prevPosition) => {
                const newPosition = { ...prevPosition };
                const container = astronautRef.current?.parentElement;

                if (container) {
                    const containerPosX = container.getBoundingClientRect().left;
                    const containerPosY = container.getBoundingClientRect().top;
                    const containerWidth = container.offsetWidth;
                    const containerHeight = container.offsetHeight;
                    const astronautWidth = astronautRef.current.offsetWidth;
                    const astronautHeight = astronautRef.current.offsetHeight;

                    // Update position
                    newPosition.x += velocity.dx;
                    newPosition.y += velocity.dy;

                    if (newPosition.x >= containerWidth - astronautWidth) {
                        setVelocity((prevVelocity) => ({ ...prevVelocity, dx: -Math.abs(prevVelocity.dx) }));
                    } else if (newPosition.x <= 0) {
                        setVelocity((prevVelocity) => ({ ...prevVelocity, dx: Math.abs(prevVelocity.dx) }));
                    }

                    if (newPosition.y >= containerHeight - astronautHeight) {
                        setVelocity((prevVelocity) => ({ ...prevVelocity, dy: -Math.abs(prevVelocity.dy) }));
                    } else if (newPosition.y <= 0) {
                        setVelocity((prevVelocity) => ({ ...prevVelocity, dy: Math.abs(prevVelocity.dy) }));
                    }
                }
                return newPosition;
            });

            // Update rotation
            setRotation((prevRotation) => prevRotation + 0.2);
        }, 16); // 60 FPS

        return () => clearInterval(interval);
    }, [velocity]);

    return (
        <div className="w-full h-full overflow-hidden absolute top-0 left-0 pointer-events-none">
            <div className="w-[calc(100vw+20vmin)] h-[calc(100vh+20vmin)] absolute top-[-10vmin] left-[-10vmin] z-9999 bg-primary flex flex-col items-center justify-center text-white overflow-hidden pointer-events-auto">
                <span className="absolute z-10 text-[30vh] text-center text-accent opacity-10 pointer-events-none leading-none">
                    404
                </span>
                <div className="absolute p-[15vmin] h-full w-full z-100 flex flex-col justify-left md:items-start md:justify-start pointer-events-auto gap-10">
                    {/* Text */}
                    <span className="z-10 text-[3vh] md:text-[4vmin] text-center md:text-left text-white/100 h-full md:h-auto leading-tight w-full md:w-[60%]">
                        <strong className="text-[8vh] md:text-[8vmin] text-accent">
                            Lost in Space&nbsp;?
                        </strong>
                        <br />
                        Sorry but the page you are looking for, has probably been absorbed by a black hole.
                    </span>

                    {/* Buttons */}
                    <div className="flex flex-col items-center md:items-start w-auto">
                        <button
                            className="text-[3vh] md:text-[3vmin] text-primary px-6 py-2 rounded-xl mt-4 w-[fit-content] bg-accent/80 hover:bg-accent"
                            onClick={() => window.history.back()}
                        >
                            Go back in Safety
                        </button>
                        <a href="/" className="text-center md:text-start text-white/40 hover:text-white pl-2 text-left text-10 md:text-[2vmin] mt-4 hover:underline w-full leading-none" >Go to Home</a>
                    </div>
                </div>

                {/* Astronaut */}
                <div className="relative w-full h-full overflow-hidden pointer-events-none">
                    <img
                        ref={astronautRef}
                        src="/assets/img/404/astronaut-lost.png"
                        alt=""
                        className="absolute w-[60vmin] h-[60vmin] z-20 pointer-events-none"
                        style={{
                            left: `${position.x}px`,
                            top: `${position.y}px`,
                            transform: `rotate(${rotation}deg)`,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
